














































































import FormCheckboxField from "@/components/form-components/FormCheckboxField.vue";
import VForm from "@/components/VForm.vue";
import { Component, Prop } from "vue-property-decorator";
import { UserManagementUser } from "@/models/user";
import accessControl from "@/api/access_control";
import { cloneDeep, isEqual, sortBy, without, xor } from "lodash";
import Loader from "@/components/Loader.vue";
import { Permission, Right } from "@/models/role";
import UserManagementRights from "@/components/usermanagement/UserManagementRights.vue";
import CheckboxField from "@/components/form-components/CheckboxField.vue";
import { DocumentCategory } from "@/enums/DocumentCategory";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Component({
  components: {
    CheckboxField,
    FormCheckboxField,
    VForm,
    Loader,
    UserManagementRights,
    TheHeader,
    Page,
  },
})
@Translatable("usermanagement")
export default class UserManagementDetail extends mixins<TranslatableComponent>() {
  private userLoading: boolean = true;
  private user: UserManagementUser | null = null;
  private clonedUser: UserManagementUser | null = null;
  private formLoading: boolean = false;
  private isDeleting: boolean = false;
  private ns: string = "views.usermanagement.user_management_detail.";
  private formIsSaving: boolean = false;
  private addUserToOtherEntityAfterCreate: boolean = false;
  UserManagementUser = UserManagementUser;

  @Prop({ required: true, type: Boolean })
  readonly edit!: boolean;

  @Prop({ required: true, type: String })
  readonly contactId!: string;

  private get formIsDisabled(): boolean {
    return (
      isEqual(sortBy(this.user?.roles), sortBy(this.clonedUser?.roles)) ||
      this.isDeleting ||
      this.formIsSaving
    );
  }

  private toggleUserRole(ability: Permission): void {
    if (!this.clonedUser) return;
    this.clonedUser.roles = xor(this.clonedUser.roles, [ability]);
  }

  private onMatrixChange(data: {
    documentCategory: DocumentCategory;
    right: Right | null;
  }) {
    if (!this.clonedUser) return;

    const { documentCategory, right } = data;
    const ability = `${documentCategory}_${right}` as Permission;

    const existingRoleInDocumentCategory: Permission | null =
      this.clonedUser.roles.find((activeRole) =>
        activeRole.startsWith(documentCategory)
      ) || null;

    if (existingRoleInDocumentCategory) {
      this.clonedUser.roles = without(
        this.clonedUser.roles,
        existingRoleInDocumentCategory
      );
    }

    if (right) {
      this.clonedUser.roles.push(ability);
    }
  }

  mounted() {
    this.getUser();
  }

  private getUser() {
    this.userLoading = true;
    accessControl.usermanagement.contacts
      .contact(this.contactId)
      .then((data) => {
        this.user = data.data;
        this.clonedUser = cloneDeep(this.user);
        this.userLoading = false;
      })
      .catch(() => {
        this.$snotify.error(this.$t(this.ns + "fetchUserError"));
        this.$router.push({ name: "usermanagement" });
      });
  }

  private deleteUser(user: UserManagementUser) {
    const answer = confirm(
      this.$t(this.ns + "deleteUserConfirmation", {
        name: user.fullName,
      }) as string
    );
    if (!answer) return;
    this.isDeleting = true;

    accessControl.usermanagement.contacts
      .delete(user.id)
      .then(() => {
        this.$snotify.success(
          this.$t(this.ns + "deleteUserSuccess", {
            name1: user.fullName,
            name2: user.account.name,
          }) as string
        );
        this.$router.push({ name: "usermanagement" });
      })
      .catch(() => {
        this.$snotify.error(
          this.$t(this.ns + "deleteUserError", {
            name1: user.fullName,
            name2: user.account.name,
          }) as string
        );
      })
      .finally(() => {
        this.isDeleting = false;
      });
  }

  onSubmit(event: Event) {
    this.formIsSaving = true;
    return new Promise((resolve, reject) => {
      accessControl.users.contacts.roles
        .update(
          this.clonedUser?.id || "",
          this.clonedUser?.roles || ([] as Permission[])
        )
        .then((data) => {
          this.$snotify.success(this.$t("views.generic.saveSuccess"));
          if (!this.edit && this.addUserToOtherEntityAfterCreate) {
            this.$router.push({
              name: "usermanagement-link",
              query: { userId: this.user?.naturalPersonId },
            });
          } else {
            this.$router.push({ name: "usermanagement" });
          }
          resolve(data);
        })
        .catch((e) => {
          this.$snotify.error(this.$t("views.generic.saveError"));
          reject(e);
        })
        .finally(() => {
          this.formIsSaving = false;
        });
    });
  }

  onCancel(event: Event) {
    this.$router.push({ name: "usermanagement" });
  }
}
